export default class NavbarEnum {
    static BODY = 'body'
    static NAVBAR = '[data-element="navbar"]'
    static LOGO = '[data-element="navbar-logo"]'
    static ECOMODE = '[data-id="eco-bar"]'
    static BURGER_MENU_TRIGGER = '[data-trigger="burger-menu-toggle"]'
    static BURGER_MENU_MORE = '[data-trigger="burger-menu-more"]'
    static BURGER_MENU_PREV = '[data-trigger="burger-menu-prev"]'
    static BURGER_SUBMENU = '[data-element="burger-submenu"]'
    static MENU_FIRST_LEVEL_LINK =
        '.menu__first-level__item.has-second-level .menu__first-level__item__link'
    static MENU_FIRST_LEVEL_ITEM = '.menu__first-level__item.has-second-level'
    static MENU_SECOND_LEVEL_LINK =
        '.menu__second-level__item.has-third-level .menu__second-level__item__link'
    static MENU_SECOND_LEVEL_ITEM = '.menu__second-level__item.has-third-level'
}
