/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import HeaderService from './services/header.service'
import LayoutService from './services/layout.service'

document.addEventListener('DOMContentLoaded', () => {
    new HeaderService()
    new LayoutService()

    //Front page only
    if ($('.home').length > 0) {
        import(
            '@scripts/services/home.service' /* webpackChunkName: "scripts/home.service" */
        ).then(({ default: HomeService }) => {
            new HomeService()
        })
    }
})
