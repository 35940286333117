import NavbarEnum from '../enumerators/navbar.enum'

export default class UtilsService {
    static changeLogoColor(color) {
        const logo = $(NavbarEnum.LOGO)
        let logoWhite
        let logoClassic

        logoWhite = IRISCollectionCustomer.imageLogoWhite
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'white') {
            logo.attr('src', logoWhite)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }
}
