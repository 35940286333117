export default class LayoutService {
    constructor() {
        if ($('.fullscreen').length > 0) {
            import(
                '@scripts/components/fullscreen.component' /* webpackChunkName: "scripts/fullscreen.component" */
            ).then(({ default: FullscreenComponent }) => {
                new FullscreenComponent()
            })
        }
    }
}
