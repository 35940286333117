import NavbarEnum from '../enumerators/navbar.enum'
import UtilsService from '../services/utils.service'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.showSubmenu()
    }

    static sticky() {
        let lastScrollTop = 0
        let stateOnTop = true

        $(window).on('load scroll', function () {
            const offset = 114 // Navbar default height
            const offsetOnScroll = 114 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const scrollTop = $(this).scrollTop()
            let hasBanner = false

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                hasBanner = true
            }

            // Hidden on scroll comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
            }
        })
    }

    static burger() {
        //Open/Close menu
        $(NavbarEnum.BURGER_MENU_TRIGGER).on('click', () => {
            $(NavbarEnum.BODY).toggleClass('menu-open')

            if (window.matchMedia('(min-width: 1024.1px)').matches) {
                if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                    UtilsService.changeLogoColor('white')
                } else {
                    UtilsService.changeLogoColor('classic')
                }
            } else {
                $(NavbarEnum.MENU_FIRST_LEVEL_ITEM).removeClass('second-level-is-open')
                $(NavbarEnum.MENU_SECOND_LEVEL_ITEM).removeClass('third-level-is-open')
            }

            //Hide ecomode
            $(NavbarEnum.ECOMODE).addClass('eco-bar--hidden')
        })

        //Open/Close submenu
        $(NavbarEnum.BURGER_MENU_MORE).on('click', function () {
            $(this).next(NavbarEnum.BURGER_SUBMENU).toggleClass('submenu-open')
        })

        $(NavbarEnum.BURGER_MENU_PREV).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
        })
    }

    static showSubmenu() {
        if (window.matchMedia('(max-width: 1024px)').matches) {
            // To open the second level
            $(NavbarEnum.MENU_FIRST_LEVEL_LINK).on('click', (e) => {
                e.preventDefault()
            })

            $(NavbarEnum.MENU_FIRST_LEVEL_ITEM).on('click', function () {
                $('.menu__first-level__item.has-second-level').removeClass('second-level-is-open')
                $(this).addClass('second-level-is-open')
            })

            // To open the third level
            $(NavbarEnum.MENU_SECOND_LEVEL_LINK).on('click', (e) => {
                e.preventDefault()
            })

            $(NavbarEnum.MENU_SECOND_LEVEL_ITEM).on('click', function () {
                $('.menu__second-level__item.has-third-level').removeClass('third-level-is-open')
                $(this).addClass('third-level-is-open')
            })

            // Return buttons
            $(document).on('click', '[data-trigger="burger-menu-prev-first-level"]', () => {
                console.log('click back to level 1')
                $(NavbarEnum.MENU_FIRST_LEVEL_ITEM).removeClass('second-level-is-open')
            })

            $(document).on('click', '[data-trigger="burger-menu-prev-second-level"]', () => {
                console.log('click back to level 2')
                $(NavbarEnum.MENU_SECOND_LEVEL_ITEM).removeClass('third-level-is-open')
            })
        }
    }
}
